/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming'; // Plus imports for other components in your app.
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core(); // define a real custom palette (using http://mcg.mbitson.com)
$primary-color: (
  50 : #e5e5e5,
  100 : #bdbdbd,
  200 : #929191,
  300 : #666565,
  400 : #454444,
  500 : #242323,
  600 : #201f1f,
  700 : #1b1a1a,
  800 : #161515,
  900 : #0d0c0c,
  A100 : #ff5454,
  A200 : #ff2121,
  A400 : #ed0000,
  A700 : #d40000,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$secondary-color: (
  50 : #fdf6e4,
  100 : #fbe9bc,
  200 : #f9db8f,
  300 : #f6cc62,
  400 : #f4c140,
  500 : #f2b61e,
  600 : #f0af1a,
  700 : #eea616,
  800 : #ec9e12,
  900 : #e88e0a,
  A100 : #ffffff,
  A200 : #fff1df,
  A400 : #ffdaac,
  A700 : #ffcf92,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$custom-primary: mat-palette($primary-color);
$custom-accent: mat-palette($secondary-color); // The warn palette is optional (defaults to red).
$custom-warn: mat-palette($mat-red); // Create the theme object (a Sass map containing all of the palettes).
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn); // Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($custom-theme);

.background-primary {
  background: mat-color($custom-primary);
}

.color-primary {
  color: mat-color($custom-primary);
}

.background-accent {
  background: mat-color($custom-accent);
}

.warning-message {
  background: mat-color($custom-warn);
}

.third-color {
  color: #f0284a;
}

.third-background {
  background: black;
}